<template>
    <MainAppContainer>
        <About />
        <DownloadApp />
    </MainAppContainer>
</template>
<script>
import MainAppContainer from '@/components/containers/MainAppContainer.vue'
import About from '@/components/pages/About.vue'
import DownloadApp from '@/components/common/DownloadApp'
export default {
    components: {
        MainAppContainer,
        About,
        DownloadApp
    }
}
</script>