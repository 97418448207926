<template>
    <MainAppContainer>
        <Pricing />
        <DownloadApp />
    </MainAppContainer>
</template>
<script>
import MainAppContainer from '@/components/containers/MainAppContainer.vue'
import Pricing from '@/components/pages/Pricing.vue'
import DownloadApp from '@/components/common/DownloadApp'
export default {
    components: {
        MainAppContainer,
        Pricing,
        DownloadApp
    }
}
</script>