<template>
    <div>
        <!---------- Banner ---------->
        <div id="pricing_banner" :style="bannerImage">
            <section class="container">
                <div class="row">
                    <div class="col text-center">
                        <h2>{{pricingData.title}}</h2>
                        <div v-if="$storage.getStorageSync('token')" data-aos="fade-right" data-aos-duration="1500">
                            <router-link :to="{name:'BusinessDashboard'}" v-if="$storage.getStorageSync('user').user_role == 'business'" class="btn btn-ondaq-dark py-2 px-5 mt-3">Get Started</router-link>
                            <router-link :to="{name:'AddBusiness'}" v-else class="btn btn-ondaq-dark py-2 px-5 mt-3">Get Started</router-link>
                        </div>
                        <div v-else data-aos="fade-right" data-aos-duration="1500">
                            <a href="#" data-bs-toggle="modal" data-bs-target="#signupModal" class="btn btn-ondaq-dark py-2 px-5 mt-3">Start Free Trial</a>
                            <p class="mb-0 mt-3">30-day free trial</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!---------- Subscription Plan ---------->
        <div id="pricing_subscription_plan">
            <section class="container px-3 px-sm-0">
                <!-- <div class="row pb-3">
                    <div class="col text-center">
                        <h4>Subscription Plan</h4>
                    </div>
                </div>
                <div class="row text-center" id="pricing_diagonal_box">
                    <div class="col" style="background-color: #f1f4fa; background-image: none;">
                        <button>
                            Individual<br />
                            <span style="font-size: 11px; color: black;">1 user</span>
                        </button>
                    </div>
                    <div class="col">
                        <button>
                            Partner<br />
                            <span>2 user</span>
                        </button>
                    </div>
                    <div class="col">
                        <button>
                            Triplet<br />
                            <span>3 user</span>
                        </button>
                    </div>
                    <div class="col">
                        <button>
                            Quad<br />
                            <span>4 user</span>
                        </button>
                    </div>
                    <div class="col">
                        <button>
                            Quint<br />
                            <span>5 user</span>
                        </button>
                    </div>
                    <div class="col pricing_diagonal_box_width">
                        <button>
                            Unlimited<br />
                            <span>Unlimited user</span>
                        </button>
                    </div>
                    <div class="col pricing_diagonal_box_width" style="background-color: #6d3479; background-image: none;">
                        <button style="color: #ffff;">
                            Pay As you Earn<br />
                            <span style="font-size: 11px;">Unlimited user</span>
                        </button>
                    </div>
                </div>
                <div class="row text-center justify-content-center">
                    <div class="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-12 border border-light shadow-sm py-3 py-md-5 me-3">
                        <h4>Monthly Subscription Plan</h4>
                        <p class="text-muted mt-4"><span class="fs-1 text-dark fw-bold">$30</span>/User</p>
                        <p class="text-muted fst-italic"> Additional users <span class="text-dark fw-bold">$10</span></p>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-12 border border-light shadow-sm py-3 py-md-5">
                        <h4>Pay As You Earn</h4>
                        <p class="text-muted">Unlimited Users</p>
                        <h6 class="text-muted">12% + 75¢ / Transaction </h6>
                        <p class="text-muted fst-italic">(Including Stripe fees)</p>
                    </div>
                    <h6 class="mt-4 fw-normal">Businesses can use both products; this depends on the size of your team and the services you’re offering. </h6>
                </div> -->

                <div class="row text-center flex">
                    <div class="col-sm-6 col-10">
                        <div id="prcing_feature_details">
                            <div class="prcing_feature_details_ellipse">
                                <h4>Scheduling Appointment Features (Booking)</h4>
                            </div>
                            <div class="prcing_feature_details_list">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">Unlimited Booking</li>
                                    <li class="list-group-item">Easy-to-Use Calendar</li>
                                    <li class="list-group-item">Send Quotation easily</li>
                                    <li class="list-group-item">Standard Booking <Tooltip :tooltipText="'This feature allows professionals/businesses to block their schedules for the full duration of a particular appointment. For example, A client has scheduled a 60-minute service starting at 11:00 AM. If the service is classified as a standard booking then the professional’s calendar will show that he/she does not have availability for 60 minutes starting at 11:00 AM and ending at 12:00 PM.'"><fa icon="info-circle" class="ml-1 text-black-50" /></Tooltip> </li>
                                    <li class="list-group-item">Flex Booking <Tooltip :tooltipText="'This feature allows professionals/businesses to show availability even though they are already booked for a particular service with a client. When entering a service, flex booking should be selected if the professional will not be attending to a client for the entire duration of service. For example, if a client books a 50-minute wash and deep conditioning treatment, there may be an initial wash time of 5 minutes then a deep conditioning time of 30 minutes, and a finish time of 15 minutes. The 30 minutes that the client is under the dryer for the deep conditioning treatment could be classified as “Flex” time. This 30-minute availability will be shown on the professional’s calendar.'"><fa icon="info-circle" class="ml-1 text-black-50" /></Tooltip></li>
                                    <li class="list-group-item">In-App Messaging</li>
                                    <li class="list-group-item">Business Profile Page </li>
                                    <li class="list-group-item">Gallery </li>
                                    <li class="list-group-item">Add Stripe POS</li>
                                    <li class="list-group-item">Automated texts messages <Tooltip :tooltipText="'This feature allows professionals/businesses to block their schedules for the full duration of a particular appointment. For example, A client has scheduled a 60-minute service starting at 11:00 AM. If the service is classified as a standard booking then the professional’s calendar will show that he/she does not have availability for 60 minutes starting at 11:00 AM and ending at 12:00 PM.'"><fa icon="info-circle" class="ml-1 text-black-50" /></Tooltip></li>
                                    <li class="list-group-item">Owner’s Dashboard </li>
                                    <li class="list-group-item">Stylist/Professional Dashboard </li>
                                    <li class="list-group-item">Email Marketing Campaign </li>
                                    <li class="list-group-item">Secure Online Payments </li>
                                    <li class="list-group-item">Ondaq Support </li>
                                    <li class="list-group-item">View analytic from dashboard <br>Stripe transaction fees <br>3.2% + 75 c </li>
                                    <li class="list-group-item">Web/Mobile Application</li>
                                    <li class="list-group-item">Web/Mobile Application</li>
                                    <li class="list-group-item">Cancellation/No-Show Policies </li>
                                    <!-- <li class="list-group-item">Mobile Application <i class="ms-1 text-danger"> Launching soon</i> </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-10">
                        <div id="prcing_feature_details" style="min-height: 956px">
                            <div class="prcing_feature_details_ellipse fw-light">
                                <h4>Queue Management Features (Virtual Queue)</h4>
                                <!-- <p> This feature helps you organize your activities by allowing clients to join the waitlist from anywhere within 7 miles.</p> -->
                            </div>
                            <div class="prcing_feature_details_list">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">Unlimited tickets</li>
                                    <li class="list-group-item">Add your apprentice</li>
                                    <li class="list-group-item">Manage your traffic</li>
                                    <li class="list-group-item">Estimated wait time</li>
                                    <li class="list-group-item" style="min-height: 41px;"></li>
                                    <li class="list-group-item" v-for="i in 14" :key="i"><fa class="checkcircle" :class="i == 11 ? 'my-4':''" :icon="['fas', 'check']" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-sm-12 text-center">
                    <button class="btn btn-ondaq-dark px-5 py-2">Start Free Trial</button>
                    <p class="mb-0" style="color: #8a8a8a; font-size: 14px; padding: 10px 0px;">30-day free trial</p>
                </div> -->
            </section>
        </div>
        <!---------- Payment Method ---------->
        <section class="container">
            <div class="row text-center">
                <div class="col-lg-4 col-md-6">
                    <div class="pricing_payment_methods_box">
                        <div style="padding-top: 60px; padding-bottom: 10px;">
                            <h4>Pay As You Earn</h4>
                        </div>

                        <div>
                            <p style="color: #626060;">Only pay for those services per transaction, and no monthly fees.</p>
                        </div>

                        <div>
                            <h5 class="mb-0">12% + 75 c / Transaction </h5>
                            <p style="color: #626060; font-weight: bold; font-size: 12px;">Including Stripe Processing fee</p>
                            <a href="" style="color: #6d3479;">Learn more <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/arrow_right.png" /></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="pricing_payment_methods_box">
                        <div style="padding-top: 60px; padding-bottom: 10px;">
                            <h4>Payout</h4>
                        </div>

                        <div>
                            <p style="color: #626060;">Cash out 100%!! We do not charge transaction fees when you withdraw your money</p>
                            <h5 class="mb-0">$0</h5>
                        </div>

                        <div style="padding-top: 35px;">
                            <a href="" style="color: #6d3479;">Learn more <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/arrow_right.png" /></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="pricing_payment_methods_box">
                        <div style="padding-top: 70px;">
                            <h4 style="font-weight: bolder; line-height: 0.5;" class="mb-4">Need Help?</h4>
                            <!-- <p style="color: #626060; font-weight: bold; font-size: 12px;">Cc Processing Fee</p> -->
                        </div>

                        <div>
                            <p style="color: #626060;">Send an email to:</p>
                        </div>

                        <div>
                            <h5 style="margin-bottom: 65px;" class="fw-bold">support@ondaq.com</h5>
                            <a :href="helpCenterURL" style="color: #6d3479;" >Help center <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/arrow_right.png" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!---------- Frequently Asked Questions ---------->
        <section class="container">
            <div class="row">
                <div class="col-12 pb-4 text-center">
                    <h3>Frequently Asked Questions</h3>
                </div>
                <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                    <div class="accordion" id="myAccordion">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                                <button type="button" class="accordion-button collapsed border-bottom" data-bs-toggle="collapse" data-bs-target="#collapseThree">When do I get paid?</button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse show">
                                <div class="card-body">
                                    <p class="p-3 m-0">As a business the money you make is reflected on your dashboard and we have partnered with one of the largest payment gateway Stripe to payout daily. However, our dedicated team will support you if you have any payment related problems.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFour">
                                <button type="button" class="accordion-button border-bottom" data-bs-toggle="collapse" data-bs-target="#collapseFour">How much do you charge me?</button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse">
                                <div class="card-body">
                                    <p class="p-3 m-0">We charge a total 8.5% + 50Cent per transaction, this includes all fees and no hidden fees, there are no separate fees to use features that are important to you.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>


<script setup>
    import { ref, onMounted } from 'vue'
    import axios from 'axios'
    import Tooltip from "@/components/common/Tooltip.vue"

    const pricingData = ref([])
    const bannerImage = ref('')
    const helpCenterURL = ref(process.env.VUE_APP_ZENDESK_SUB_DOMAIN)
    function bannerBgImage(img){
        bannerImage.value = 'background-image: url("'+img+'")'
    }
    function bannerData(){
        axios.get('bannerData/Pricing').then(function (response) {
            if (response.data.status == 'success') {
                pricingData.value = response.data.data[0]
                bannerBgImage(response.data.data[0].banner_image)
            }
        });
    }

    onMounted(() => {
        bannerData()
    })
</script>

<style scoped>
    .accordion-button{
        background-color: #ffffff;
        font-size: 1.2rem;
        color: #640e99
    }
    .accordion-button::after{
        background-image: url('../../assets/images/pricing/down.svg')
    }
    .list-group-item{
        border-color: rgba(0,0,0,.04)
    }
    .shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.35)!important;
    }
    .svg-inline--fa.fa-w-16{
        color: purple;
    }

    [tooltip]:hover:after{
        white-space: unset;
        width: max-content;
    }
</style>