<template>
    <div>
        <!---------- Banner ---------->
        <div id="about_banner" :style="bannerImage">
            <section class="container">
                <div class="row">
                    <div class="col text-center">
                        <h2>{{aboutData.title}}</h2>
                    </div>
                </div>
            </section>
        </div>

        <!---------- Text Section ---------->
        <section class="container px-3 px-sm-0">
            <div class="row">
                <div class="col-12 col-md-10 text-center m-auto">
                    <p class="mb-0 fs-5" style="font-size: 17px">At OnDaQ, we believe technology should be enjoyable and our time equals money.<br> OnDaQ is a marketplace and an online platform that provides tools and marketing strategies for independent beauty professionals, salons and barbers via its web and mobile application, so business owners and professionals can render the best services to their clients.</p>
                </div>
            </div>
        </section>

        <!---------- Our Founding ---------->
        <section class="bg-light">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 ps-lg-5 text-center text-md-start">
                        <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/about/founding.jpg" class="img-fluid" />
                        <h6 class="text-center pt-3">Founded in Charlotte, North Carolina</h6>
                    </div>

                    <div class="col-md-6 ps-lg-5 pt-4 pt-md-1">
                        <h2 class="mb-3">Our Founding</h2>
                        <p>Our founder believes our time is precious and should not be wasted, In the year 2018, Abimbola discovered a process that is necessary for the barbershops and beauty salons, a few years ago, he will send text messages, make calls, or book an appointment with his barber, unfortunately, these processes isn’t working for so many people including the businesses. He started researching and developing a process with his team that will help the businesses get more clients and manage their client’s time. It is through this journey that the ondaq Queuing Management Systems was born. We started OnDaQ beauty blog in 2018 and today we have the best last-minute platform in the industry.</p>
                        <!-- <p>OnDaQ was founded by Abimbola and Shinnell Aregbesola in 2018. Initially, they focused on developing an application to support small businesses with the virtual queuing system, which is a real-time queue that solved the problems of waiting, sometimes waiting is necessary but not for too long. During their wedding, they invited friends and family, Abimbola and his brothers walked into a nearby barbershop only to wait for over an hour before they called 1 of them. The frustration of waiting for these services for so many people is a waste of our precious time. That is why we say our time equals money.</p> -->
                        <!-- <p>Today, Ondaq is a whole package with a Scheduling and Virtual Queuing System to support Independent Beauty Professionals, Salon Owners, Wellness Professionals, and Barbers so they can focus on rendering the best services to their clients. Ondaq will be available on the web, IOS, and Android platforms.</p> -->
                        <a href="#" style="color: #6e317a;" class="pe-2"> Learn more</a>
                        <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/about/arrow_right.png" class="img-fluid" />
                    </div>
                    
                </div>
            </div>
        </section>

        <!---------- Payment Method ---------->
        <section class="container">
            <div class="row text-center">
                <div class="col-12">
                    <h4 class="mb-4">For more information about our company</h4>
                </div>
                <div class="col-md-4">
                    <div class="about_our_company_box">
                        <h4 class="d-inline-block">Our Blog</h4>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="about_our_company_box">
                        <h4 class="d-inline-block">News</h4>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="about_our_company_box">
                        <a :href="helpCenterURL"><h4 class="d-inline-block">Help Center</h4></a>
                    </div>
                </div>
            </div>
        </section>

        <!---------- Our Partners ---------->
        <!-- <section class="container">
            <div class="row text-center">
                <div class="col-12">
                    <h4 class="mb-5">Our Partners</h4>
                </div>
                <div class="col-sm-4 mb-5 mb-sm-0">
                    <img src="@/assets/images/about/logo1.png" class="img-fluid" style="width: 100px" />
                </div>
                <div class="col-sm-4 mb-4 mb-sm-0">
                    <img src="@/assets/images/about/logo2.png" class="img-fluid" style="width: 140px" />
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                    <img src="@/assets/images/about/logo3.png" class="img-fluid" style="width: 200px" />
                </div>
            </div>
        </section> -->
    </div>
</template>


<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                aboutData:[],
                bannerImage: '',
                helpCenterURL : process.env.VUE_APP_ZENDESK_SUB_DOMAIN
            }
        },
        methods:{
            bannerBgImage(img){
                this.bannerImage = 'background-image: url("'+img+'")'
            },
            bannerData(){
                let thiss = this
                axios.get('bannerData/About Us').then(function (response) {
                    if (response.data.status == 'success') {
                        thiss.aboutData = response.data.data[0]
                        thiss.bannerBgImage(thiss.aboutData.banner_image)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 422) {
                            thiss.toast.error(error.response.data.message);
                        }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                            thiss.toast.error(error.response.data.message);
                        }
                    }
                });
            }
        },
        mounted() {
            this.bannerData()
        }
    }
</script>

<style scoped>
#about_banner {
	background-color: #f7f4f7;
	background-position: center;
	background-repeat: no-repeat;
    background-size: contain;
	padding-top: 150px;
	padding-bottom: 150px
}
#about_banner h2{
    color: #1f2020
}
.about_our_company_box{
	margin-bottom: 20px;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 5px;
    height: 20rem;
    line-height: 20rem;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
}
.about_our_company_box:hover, .about_our_company_box:active{
	box-shadow: 0px 5px 20px rgba(109, 52, 121, 0.25);
}
</style>